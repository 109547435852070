<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <v-form v-model="isValid">

                <v-row>
                    <v-col
                        v-if="editedItem.id"
                        cols="12"
                        md="3"
                        lg="2"
                        sm="4"
                    >
                        <v-text-field
                            v-model="editedItem.id"
                            class="purple-input"
                            :label="$t('admin.games.id')"
                            :disabled="true"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-select
                            v-model="editedItem.game_type_id.value"
                            :error-messages="editedItem.game_type_id.error"
                            :items="gameTypes"
                            attach
                            :label="$t('admin.games.type')"
                            item-text="name"
                            item-value="id"
                            :rules="[rules.required]"
                            :disabled="isOnlyView || !!item"
                            @change="editedItem.game_type_id.error = ''"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="3"
                        lg="2"
                        sm="4"
                    >
                        <v-text-field
                            v-model="editedItem.teams_count.value"
                            class="purple-input"
                            :label="$t('admin.games.teamsCount')"
                            :error-messages="editedItem.teams_count.error"
                            :rules="[rules.validNumberOrEmpty]"
                            :disabled="isOnlyView || !!item"
                            @keyup="editedItem.teams_count.error = ''"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-autocomplete
                            v-model="editedItem.company.value"
                            :items="companies"
                            :label="$t('companies.selectCompany')"
                            item-text="attributes.name"
                            item-value="id"
                            :error-messages="editedItem.company.error"
                            clearable
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="2"
                        class="mb-0 pb-0"
                    >
                        <v-file-input
                            v-model="editedItem.img.value"
                            :label="$t('admin.companies.logo')"
                            accept="image/*"
                            :error-messages="editedItem.img.error"
                            :disabled="isOnlyView"
                            @change="preview_img"
                        />
                        <v-img
                            class="img_preview"
                            :src="editedItem.img.url"
                            contain
                            :height="editedItem.img.url ? '100px' : '20px'"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        class="mb-0 pb-0"
                    >
                        <v-text-field
                            v-model="editedItem.name.value"
                            class="purple-input"
                            :label="$t('common.name')"
                            :error-messages="editedItem.name.error"
                            :disabled="isOnlyView"
                            @keyup="editedItem.name.error = ''"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        lg="7"
                        class="mb-0 pb-0"
                    >
                        <v-text-field
                            v-model="editedItem.description.value"
                            class="purple-input"
                            :label="$t('common.description')"
                            :error-messages="editedItem.description.error"
                            :disabled="isOnlyView"
                            @keyup="editedItem.description.error = ''"
                        />
                    </v-col>


                    <v-col
                        v-if="hasCards"
                        cols="12"
                        sm="12"
                    >
                        <v-divider class=" mb-4 mx-8 "/>
                        <!--                              :disabled="isOnlyView"-->

                        <v-select
                            v-model="editedItem.cardTypes.value"
                            :error-messages="editedItem.cardTypes.error"
                            :items="cardTypes"
                            attach
                            chips
                            :label="$t('admin.games.cardTypes')"
                            multiple
                            item-text="name"
                            item-value="id"
                            @change="changeCardTypesSelected"
                        >
                            <template v-slot:selection="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <span v-text="item.name"
                                                  @click.stop="$router.push(`/app/admin/cards?type=${item.id}`);"
                                            />
                                        </v-chip>

                                    </template>
                                    <span v-text="$t('admin.cards.typeOptions.tooltipClickForGo')"/>
                                </v-tooltip>


                            </template>
                        </v-select>
                    </v-col>
                    <!--                <cards-game
              v-if="editedItem && editedItem.id"
              :game-id="editedItem.id"
          />-->


                    <v-col cols="12" v-if="hasPlayers">
                        <v-divider class=" mb-4 mx-8 "/>
                        <players-game

                            :game-id="editedItem.id"
                            :teams_count="editedItem.teams_count.value"
                        />
                    </v-col>


                    <!--              HAS DATE TIME to FINISH GAME       -->
                    <v-col
                        v-if="hasDateRange"
                        cols="12"
                        sm="10"
                        md="7"
                        lg="5"
                        class="d-flex flex-wrap"
                    >
                        <div class="mr-3">
                            <v-datetime-picker

                                :label="$t('admin.games.start_at')"
                                v-model="editedItem.startAt.value"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon v-text="">mdi-calendar</v-icon>
                                </template>

                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock-time-four-outline</v-icon>
                                </template>
                            </v-datetime-picker>
                        </div>

                        <div class="mr-3">
                            <v-datetime-picker
                                :label="$t('admin.games.ends_at')"
                                v-model="editedItem.endsAt.value"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon v-text="">mdi-calendar</v-icon>
                                </template>

                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock-time-four-outline</v-icon>
                                </template>
                            </v-datetime-picker>
                        </div>
                    </v-col>


                    <v-col
                        v-if="hasAllowModifyAnswerAfterSubmit"
                        cols="6"
                        sm="4"
                        md="3"
                    >
                        <label class="text-label"
                               v-text="'Permitir modificar respuesta al terminar'"
                        />
                        <v-switch
                            v-model="editedItem.allow_modify_answer"
                            class="mx-2 mt-0 pt-0"
                            :label="editedItem.allow_modify_answer ? $t('admin.users.enabledText') : $t('admin.users.disabledText')"
                        />
                    </v-col>

                    <v-col
                        v-if="hasShowResultAfterSubmitOrGameEnd"
                        cols="6"
                        sm="4"
                        md="3"
                    >
                        <label class="text-label"
                               v-text="'Mostrar resultados al terminar'"
                        />
                        <v-switch
                            v-model="editedItem.show_result"
                            class="mx-2 mt-0 pt-0"
                            :label="editedItem.show_result ? $t('admin.users.enabledText') : $t('admin.users.disabledText')"
                        />
                    </v-col>

                    <v-col
                        v-if="hasCardsNumberMax"
                        cols="12"
                        sm="4"
                        md="3"
                        lg="2"
                    >
                        <v-text-field
                            v-model="editedItem.cards_max.value"
                            class="purple-input"
                            :label="$t('admin.games.cards_max')"
                            :error-messages="editedItem.cards_max.error"
                            :rules="[rules.validNumberOrEmpty]"
                            :disabled="isOnlyView"
                            @keyup="editedItem.cards_max.error = ''"
                        />
                    </v-col>

                    <body-editor
                        :is-only-view="isOnlyView"
                        :body-value="editedItem.feedforward_es.value"
                        :body-value-initial="(item && item.feedforward_es) ? item.feedforward_es : ''"
                        :title="$t('admin.games.feedforward_es')"
                        @update:bodyValue="editedItem.feedforward_es.value = $event"
                    />

                    <body-editor
                        :is-only-view="isOnlyView"
                        :body-value="editedItem.feedforward_en.value"
                        :body-value-initial="(item && item.feedforward_en) ? item.feedforward_en : ''"
                        :title="$t('admin.games.feedforward_en')"
                        @update:bodyValue="editedItem.feedforward_en.value = $event"
                    />

                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions class="flex-wrap justify-end">
            <v-btn
                v-if="!!item && gameTypeHelper.hasInfo(item)"
                color="blue darken-1"
                text
                @click="infoItem(item)"
            >
                {{ $t('common.infoTitle') }}
            </v-btn>

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.${isOnlyView || item ? 'endProcess' : 'confirmDialogCancel'}`) }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import GamesAPIService from "@/services/GamesAPIService";
import CompaniesAPIService from "@/services/CompaniesAPIService";

export default {
    components: {
        PlayersGame: () => import('@/components/admin/games/playersGameList'),
        BodyEditor: () => import('@/components/admin/posts/form/bodyEditor')
    },
    data: function () {
        return {
            urlMain: 'admin/games/games',
            rules: {
                ...rules
            },
            gameId: null,
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            gameTypes: [],
            cardTypes: [],
            companies: []
        }
    },
    computed: {
        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t('admin.games.view') : this.$t('admin.games.edit')
            } else return this.$t('admin.games.new')
        },
        hasCards() {
            return this.gameTypeHelper.hasCards( this.item);
        },
        hasPlayers() {
            if (!this.editedItem?.id) return false; // Firstly is necessary create the game

            return this.gameTypeHelper.hasPlayers(this.item);
        },
        hasDateRange() {
            const game_type_id = this.editedItem.game_type_id.value;
            return this.gameTypeHelper.hasDateRange( {game_type_id} );
        },
        hasAllowModifyAnswerAfterSubmit() {
            const game_type_id = this.editedItem.game_type_id.value;
            return this.gameTypeHelper.hasAllowModifyAnswerAfterSubmit( {game_type_id} );
        },
        hasShowResultAfterSubmitOrGameEnd() {
            const game_type_id = this.editedItem.game_type_id.value;
            return this.gameTypeHelper.hasAllowModifyAnswerAfterSubmit( {game_type_id} );
        },
        hasCardsNumberMax() {
            const game_type_id = this.editedItem.game_type_id.value;
            return this.gameTypeHelper.hasCardsNumberMax( {game_type_id} );
        }
    // ...mapGetters(['role', 'cities'])
    },
    async created() {
        // const itemParam = this.$route.params.item;

        this.gameId = this.$route.params.id;

        /* if (itemParam) {
        this.item = itemParam;
    } */

        if (Number.isInteger(+this.gameId)) {
            await this.fetchGame();
        }

        if (this.item) {
            this.editedItem = this.getEditedItem(this.item);
        }

        if (this.hasCards) {
            await this.fetchCardTypes();
        }

        const company = this.$route.query.company;
        if(company)
            this.editedItem.company.value = company;

        await this.fetchGameTypes();
        await this.fetchCompanies();
    },
    /* watch: {
      dialog(val) {
          if (val) {
              this.editedItem = this.getEditedItem()
          }
      }
  }, */
    mounted() {
    },
    methods: {
        getEditedItem(itemInit = null) {
            const item = itemInit ?? null;

            var empty = !item

            return {
                id: item?.id ?? '',
                game_type_id: {
                    value: empty ? '' : +item.game_type_id,
                    error: ''
                },
                teams_count: {
                    value: empty ? '' : +item.teams_count,
                    error: ''
                },
                active: {
                    value: item?.active ?? '',
                    error: ''
                },
                cardTypes: {
                    value: (empty || !item.card_types) ? [] : item.card_types.map(type => type.id),
                    error: ''
                },
                name: {
                    value: item?.name ?? '',
                    error: ''
                },
                description: {
                    value: item?.description ?? '',
                    error: ''
                },
                endsAt: {
                    value: item?.ends_at ? new Date(item.ends_at) : '',
                    error: ''
                },
                startAt: {
                    value: item?.start_at ? new Date(item.start_at) : '',
                    error: ''
                },
                allow_modify_answer: item?.allow_modify_answer ?? false,
                show_result: item?.show_result ?? true,
                company: {
                    value: '' + item?.company_id ?? '',
                    error: ''
                },
                feedforward_es: {
                    value: item?.feedforward_es ?? '',
                    error: ''
                },
                feedforward_en: {
                    value: item?.feedforward_en ?? '',
                    error: ''
                },
                img: {
                    value: empty || !item.logo ? [] : new File(
                        [item.logo],
                        item.logo,
                        {
                            type: "text/plain"
                        }
                    ),
                    error: "",
                    url: empty || !item.logo ? ""
                        : this.getImageRoute() + item.logo
                },
                cards_max: {
                    value: empty ? '' : +item.cards_max,
                    error: ''
                }
            }
        },

        initialize() {

        },
        async save() {
            const formData = new FormData();
            formData.append("game_type_id", this.editedItem.game_type_id.value);
            formData.append("teams_count", this.editedItem.teams_count.value ?? '0');
            formData.append("active", this.editedItem.active.value ?? '');
            formData.append("company_id", this.editedItem.company.value);
            formData.append("name", this.editedItem.name.value ?? '');
            formData.append("description", this.editedItem.description.value ?? '');

            formData.append("allow_modify_answer", this.editedItem.allow_modify_answer);
            formData.append("show_result", this.editedItem.show_result);
            formData.append("feedforward_es", this.editedItem.feedforward_es.value);
            formData.append("feedforward_en", this.editedItem.feedforward_en.value);
            formData.append("image", this.editedItem.img.value ?? '');
            formData.append("cards_max", this.editedItem.cards_max.value ?? '0');

            const start_at = this.editedItem.startAt.value instanceof Date ?
                this.editedItem.startAt.value.toISOString() : '';
            formData.append("start_at", start_at);

            const ends_at = this.editedItem.endsAt.value instanceof Date ?
                this.editedItem.endsAt.value.toISOString() : '';
            formData.append("ends_at", ends_at ?? '');

            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }


            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: {"Content-Type": "multipart/form-data"}
                    });


                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.afterCreate(result.data.game)

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                /* console.log('********** errors ********')
        console.log(error) */

                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer === '/data/attributes/title') {
                                this.editedItem.title.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/body') {
                                this.editedItem.body.error = e.detail
                            }
                        })
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        afterCreate(item) {
            // this.isOnlyView = true;

            this.editedItem = this.getEditedItem(item);
            this.item = item;

            if (this.$route.params.id === 'new') {
                this.$router.replace({params: {id: item.id}})
            }

            if (this.hasCards) {
                this.fetchCardTypes();
            }
        },
        async fetchGameTypes() {
            try {
                const urlTypes = 'admin/games/types';

                const gameTypes = await axios
                    .get(urlTypes)

                this.gameTypes = gameTypes.data.gameTypes;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async fetchGame() {
            try {
                const gameId = this.gameId;

                const controller = new AbortController();
                const signal = controller.signal;

                const game = await GamesAPIService.getGame({gameId, signal});

                this.item = game.data.game;
            } catch (e) {
                // console.log('error', e)
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async fetchCardTypes() {
            try {
                const urlTypes = 'admin/games/cards/types';

                const types = await axios
                    .get(urlTypes)

                this.cardTypes = types.data.cardTypes;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async changeCardTypesSelected() {
            this.editedItem.cardTypes.error = '';

            const formData = new FormData();
            formData.append("cards_types", this.editedItem.cardTypes.value);

            const gameId = '/' + this.editedItem.id

            try {
                const result = await axios
                    .post('admin/games/cards/types/game_card_types' + gameId, formData, {
                        headers: {"Content-Type": "multipart/form-data"}
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                }
            } catch (error) {
                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        infoItem(item) {
            this.$router.push({name: 'GameInfo', params: {item, id: item.id + ''}})
        },
        async fetchCompanies() {
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                const result = await CompaniesAPIService.getCompanies({signal});

                this.companies = result.data.data;
            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))

            }
        },
        preview_img() {
            if (this.editedItem.img.value && !(this.editedItem.img.value instanceof Array)) {
                this.editedItem.img.url = URL.createObjectURL(
                    this.editedItem.img.value
                );
            } else {
                this.editedItem.img.url = null;
                this.editedItem.img.value = [];
            }
        },
        getImageRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=games/logos/';
        }
    }
}
</script>

<style>

</style>
